import { ptBR, enUS, enCA, es } from 'date-fns/locale'

export const getUserLocale = (locale) => {
  switch (locale) {
    case 'pt':
      return ptBR

    case 'ca':
      return enCA

    case 'cl':
    case 'pe':
      return es

    default:
      return enUS
  }
}
