import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { Link, navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import { format } from 'date-fns'

import media from '../../components/css/media'

import SplitLayout from '../../components/common/splitLayout'
import Seo from '../../components/common/seo'
import Error from '../../components/common/errorMessage'

import { AuthContext } from '../../context/authProvider'

import useDeviceDetect from '../../hooks/useDeviceDetect'
import { getUserLocale } from '../../helpers/getUserLocale'

import EyeIcon from '../../images/icon_eye.svg'
import EyeSlashIcon from '../../images/icon_eye_slash.svg'

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: #ffffff;

  form {
    max-width: 80%;
    width: 100%;
    margin: 0 auto;
  }

  ${media.desktop`
    form {
      max-width: 500px;
    }
  `};
`

const ForgotPassword = styled(Link)`
  color: #000;
  text-align: center;
  margin: 2rem 0 0.5rem 0;
  display: block;
`

const JoinNowWrapper = styled.div`
  text-align: center;
`

const Eye = styled.img`
  width: 20px;
  position: absolute;
  right: 5%;
  top: 15px;
  cursor: pointer;
`

const EyeSlash = styled.img`
  width: 20px;
  position: absolute;
  right: 5%;
  top: 15px;
  cursor: pointer;
`

const JoinNow = styled(Link)`
  color: #31c27c;
`

const PasswordWrapper = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 32%;
    right: 5%;
    cursor: pointer;
  }
`

const Login = () => {
  const { isMobile } = useDeviceDetect()
  const intl = useIntl()

  const data = useStaticQuery(graphql`
    {
      otherBannersApi {
        values {
          lang
          otherBanners {
            background
            backgroundMobile
            banner_title_text
            text_color
            type
          }
        }
      }
    }
  `)

  const {
    otherBannersApi: { values },
  } = data

  const bannerExistForLocale = values.some((row) => row.lang === intl.locale)
  const bannerExistForPage =
    bannerExistForLocale &&
    values
      .filter((row) => row.lang === intl.locale)[0]
      .otherBanners.some((i) => i.type === 'login')
  const banner = bannerExistForPage
    ? values
        .filter((row) => row.lang === intl.locale)[0]
        .otherBanners.filter((i) => i.type === 'login')[0]
    : null

  const image = banner ? banner.background : null
  const imageMobile = banner ? banner.backgroundMobile : null
  const text = banner ? banner.banner_title_text : null
  const textColor = banner ? banner.text_color : null

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm()

  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const { loginUser } = useContext(AuthContext)

  const formatSelfExclusionMessage = ({ message, name, date, reason }) => {
    const locale = getUserLocale(intl.locale)

    let formatedMessage = message
      .replace('{{user}}', name)
      .replace('{{date}}', format(new Date(date), 'PPPP', { locale }))

    if (reason) {
      const reasonMessage = intl.formatMessage({ id: 'login.error.reason' })
      const selfExclusionReason = reasonMessage.replace('{{reason}}', reason)
      formatedMessage = `${formatedMessage}. ${selfExclusionReason}`
    }

    return formatedMessage
  }

  const onSubmit = async (data) => {
    setDisabled(true)

    const response = await loginUser(
      data.username?.trim(),
      data.password?.trim()
    )

    if (response.ok) {
      setDisabled(false)
      navigate(`/${intl.locale}/sports/`)
    }

    const { full_name: name, active_until: date, reason } = response.error.data

    if (!response.ok) {
      setDisabled(false)
      if (response.error.messageCode === 174) {
        setError(
          intl.formatMessage({
            id:
              'login.error.the_login_failed_please_check_your_username_and_password',
          })
        )
      } else if (response.error.messageCode === 7) {
        const message = intl.formatMessage({ id: 'login.error.self_exclusion' })

        setError(
          formatSelfExclusionMessage({
            message,
            name,
            date,
            reason,
          })
        )
      } else if (response.error.messageCode === 19) {
        const message = intl.formatMessage({ id: 'login.error.cool_off' })

        setError(
          formatSelfExclusionMessage({
            message,
            name,
            date,
            reason,
          })
        )
      } else {
        setError(response.error.message)
      }
    }
  }

  return (
    <SplitLayout
      img={isMobile ? imageMobile : image}
      text={text}
      textColor={textColor}
      hideLeftSideOnMobile={true}
    >
      <Seo
        title={intl.formatMessage({ id: `seo.login_title` })}
        description={intl.formatMessage({ id: `seo.login_description` })}
      />
      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1>
            <FormattedMessage id='login.header' />
          </h1>

          <label htmlFor='username'>
            <FormattedMessage id='userProfile.username' />
          </label>
          <input
            className={`${errors.username ? 'invalid' : ''} ${
              !errors.username && touchedFields.username ? 'valid' : ''
            }`}
            {...register('username', { required: true })}
          />

          <label htmlFor='password'>
            <FormattedMessage id='common.password' />
          </label>
          <PasswordWrapper>
            <input
              className={`${errors.password ? 'invalid' : ''} ${
                !errors.password && touchedFields.password ? 'valid' : ''
              }`}
              type={`${showPassword ? 'text' : 'password'}`}
              {...register('password', { required: true })}
            />
            {!showPassword && (
              <Eye
                src={EyeIcon}
                alt='Show Password'
                onClick={() => setShowPassword(!showPassword)}
              />
            )}
            {showPassword && (
              <EyeSlash
                src={EyeSlashIcon}
                alt='Hide Password'
                onClick={() => setShowPassword(!showPassword)}
              />
            )}
          </PasswordWrapper>

          <input
            type='submit'
            className={disabled ? 'disabled' : ''}
            disabled={disabled}
            value={intl.formatMessage({ id: 'common.login' })}
          />

          {error && <Error text={`${error}`} />}

          <ForgotPassword to={`/${intl.locale}/forgot-password/`}>
            <FormattedMessage id='login.forgotPassword' />
          </ForgotPassword>

          <JoinNowWrapper>
            <FormattedMessage id='login.dontHaveAnAccount' />{' '}
            <JoinNow to={`/${intl.locale}/register/`}>
              <FormattedMessage id='login.register' />
            </JoinNow>
          </JoinNowWrapper>
        </form>
      </Content>
    </SplitLayout>
  )
}

export default Login
