import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useIntl } from 'gatsby-plugin-intl'
import loadable from '@loadable/component'

import Header from '../nav/header'
import GlobalStyle from '../css/globalStyle'
import media from '../css/media'
import { PaymentsProvider } from '../../context/paymentProvider'

import GtmScript from '../../templates/externalScripts/googlaTagManagerScript'
import LiveAgentScript from '../../templates/externalScripts/liveAgentScipt'

const ToastContainer = loadable(() => import('./toastContainer'))

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.desktop`
    flex-direction: row;
  `};
`

const Left = styled.div`
  height: 360px;
  position: relative;
  overflow: hidden;

  ${(props) => {
    if (props.hideLeftSideOnMobile) {
      return `display: none;`
    }
  }}

  ${(props) => {
    if (props.mobileBannerStyle) {
      return `
        height: 150px;
        padding: 2rem;
        box-sizing: border-box;
      `
    }
  }}

  img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
  }

  ${media.desktop`
    flex: 0 0 50%;
    height: calc(100vh - 60px);
    display: block;
    padding: 2rem;
    box-sizing: border-box;
  `};
`

const Right = styled.div`
  height: calc(100vh - 50px);
  background-color: #fff;

  ${media.desktop`
    flex: 0 0 50%;
    height: calc(100vh - 60px);
    overflow: auto;
  `};
`

const Headline = styled.h1.attrs((props) => ({
  color: props.color || '#fff',
}))`
  color: ${(props) => props.color};
  font-size: 1.5em;
  line-height: 28px;
  text-align: left;
  top: 0;
  max-width: 70%;
  margin: 0;

  ${media.desktop`
    line-height: 80px;
    font-size: 4.118em;
    text-transform: uppercase;
    position: relative;
    width: 100%;
    height: auto;
    text-align: center;
    margin: initial;
    margin-top: 0;
    max-width: 100%;
  `};

  ${media.uhd`
    top: 3rem;
  `};
`

const SplitLayout = (props) => {
  const intl = useIntl()

  return (
    <PaymentsProvider>
      <ToastContainer />
      <GlobalStyle />
      <Header locale={intl.locale} />
      <Wrapper>
        <Left
          {...props}
          {...(props.hideLeft
            ? {
                style: {
                  height: '0',
                  visibility: 'hidden',
                },
              }
            : {})}
        >
          {props.text && (
            <Headline color={props.textColor}>{props.text}</Headline>
          )}

          {props.img && (
            <picture>
              <img
                width={100}
                height={100}
                loading='lazy'
                src={`${props.img}`}
                alt={`${props.text}`}
              />
            </picture>
          )}

          {props.img && props.img.ID && (
            <picture>
              <source
                srcSet={`${props.img.sizes.medium_large}`}
                media='(max-width: 768px)'
              />
              <img
                width={props.img.width}
                height={props.img.height}
                loading='lazy'
                src={`${props.img.url}`}
                alt={`${props.img.alt}`}
              />
            </picture>
          )}
        </Left>
        <Right>{props.children}</Right>
      </Wrapper>
      <div id='modal-root' />

      <GtmScript />
      <LiveAgentScript />
    </PaymentsProvider>
  )
}

SplitLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SplitLayout
